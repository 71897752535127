import Layout from "./common/layout"
import Head from "./common/Head"
// import AiShortVideo from "pages/product/AiShortVideo"
import underMaintenance from "images/under_maintenance.svg"
import { useFormatter } from "helpers/i18n"

const AiShortVideoPage = () => {
  const { __, currentLocale } = useFormatter()

  return (
    <>
      {/* <Head
        title={"AI Short Video"}
        description={"AI Short Video"}
        showCaptcha={true}
      />
      <Layout displayApplyBtn={false}>
        <AiShortVideo />
      </Layout> */}
      <Head
        showCaptcha={true}
        title={
          currentLocale === "en" ? "Under Maintenance" : "Hệ Thống Đang Bảo Trì"
        }
      />
      <Layout>
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            justifyContent: "center",
            color: "#2d5997",
          }}
        >
          <img
            src={underMaintenance}
            alt="Under Maintenance"
            style={{ width: "70vh" }}
          />
          <h1>
            {__({
              defaultMessage: "We are Under Maintenance.",
            })}
          </h1>
          <h4 className="mb-4">
            {__({
              defaultMessage: "Will be Back Soon!",
            })}
          </h4>
        </div>
      </Layout>
    </>
  )
}

export default AiShortVideoPage
